<template>
  <v-container fluid>
    <v-flex
      xs12
      sm12
      md12
    >
      <v-layout
        row
        style="margin: 0px"
      >
        <v-col
          xs12
          sm12
          md12
        >
          <v-card
            flat
            class="transparent pa-0 ma-0"
          >
            <v-card-text v-if="!preloader">
              <v-row class="pa-0 ma-0">
                <v-col
                  v-for="set in dataSets"
                  :key="set.id"
                  cols="12"
                  sm="6"
                  md="4"
                  class="pa-2 ma-0"
                >
                  <v-card
                    class="fill-height rounded secondarygrad pa-2"
                    style="position:relative"
                  >
                    <v-card-title>
                      <h4 class="card_title">{{ set.name }}</h4>
                      <v-spacer></v-spacer>
                      <v-menu
                        bottom
                        origin="center center"
                        transition="scale-transition"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            fab
                            icon
                            class="primarygrad"
                            x-small
                          >
                            <v-icon
                              v-if="
                                $router.currentRoute.query.topic ==
                                  'assignments'
                              "
                              size="23"
                              color="white"
                              v-bind="attrs"
                              v-on="on"
                              @click="listEdit(set)"
                            >edit</v-icon>
                            <v-icon
                              v-else
                              size="23"
                              color="white"
                              v-bind="attrs"
                              v-on="on"
                              @click="editItem(set)"
                            >edit</v-icon>
                          </v-btn>
                        </template>
                      </v-menu>
                    </v-card-title>

                    <v-card-text class="px-2 pb-15">
                      <p class="px-2">Assignments</p>

                      <chip-with-role
                        :text="companyManager.name"
                        :extra-text="companyManager.role"
                      >
                      </chip-with-role>

                      <chip-with-role
                        v-for="item in _.filter(set.accounts, (d) => {
                            return d.role.toLowerCase() != 'manager';
                          })"
                        :key="item.id"
                        :text="item.name"
                        :extra-text="item.role"
                        @crossClick="deleteAssignmentAndUser(item, set)"
                      >
                      </chip-with-role>

                    </v-card-text>

                    <v-card-actions style="position:absolute; bottom:0; right:0;">
                      <v-btn
                        v-if="$router.currentRoute.query.topic == 'assignments'"
                        class="primarygrad white--text"
                        style="margin: 7px; text-transform: none"
                        @click="selectedAssignMember(set)"
                      >
                        <v-icon
                          left
                          color="white"
                        >add</v-icon>Assign
                      </v-btn>
                    </v-card-actions>

                    <v-card-text
                      v-if="set.accounts.length < 0"
                      style="padding: 5px 0px"
                    >
                      <h3
                        v-if="$router.currentRoute.query.topic == 'assignments'"
                        style="margin: 3px 7px; font-weight: 500; font-size: 14px; letter-spacing: 0.5px; opacity: 0.9"
                      >
                        No members in this list
                      </h3>
                      <h3
                        v-if="$router.currentRoute.query.topic == 'teams'"
                        style="margin: 3px 7px; font-weight: 500; font-size: 14px; letter-spacing: 0.5px; opacity: 0.9"
                      >
                        No members in this Team
                      </h3>
                      <v-chip
                        v-if="$router.currentRoute.query.topic == 'assignments'"
                        style="margin: 7px; text-transform: none"
                        @click="selectedAssignMember(set)"
                      >
                        <v-icon
                          left
                          color="primary"
                        >add</v-icon>Assign
                      </v-chip>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-flex
                  v-if="$store.getters.user.role.toLowerCase() == 'manager'"
                  xs12
                  sm12
                  md4
                  style="padding: 7px"
                >
                  <v-card
                    v-if="$router.currentRoute.query.topic == 'assignments'"
                    class="add_button"
                    @click="openAddList"
                  >
                    <v-icon
                      size="22"
                      style="margin-right: 5px"
                    >add</v-icon>Add
                    List
                  </v-card>
                  <v-card
                    v-else
                    class="add_button"
                    @click="openDialog"
                  >
                    <v-icon
                      size="22"
                      style="margin-right: 5px"
                    >add</v-icon>Add
                    Team
                  </v-card>
                </v-flex>
              </v-row>
            </v-card-text>

          </v-card>
        </v-col>
      </v-layout>
    </v-flex>

    <!-- Dialog for list -->
    <v-dialog
      v-model="listDialog"
      persistent
      width="400"
    >
      <v-card>
        <v-card-title class="primarygrad white--text">{{
          formTitle
        }}</v-card-title>
        <v-card-text style="padding: 15px">
          <v-row style="margin: 0px">
            <v-flex
              xs12
              sm12
              md12
            >
              <v-text-field
                v-model="list.name"
                label="Name*"
              ></v-text-field>
            </v-flex>

            <v-flex
              v-if="
                formTitle != 'New' &&
                  $store.getters.user.role.toLowerCase() == 'manager'
              "
              xs12
              sm12
              md12
            >
              <v-select
                v-model="list.teamsId"
                label="Select Team"
                :items="
                  teams.filter((t) => {
                    return t.id != 'allTeams';
                  })
                "
                item-value="id"
                item-text="name"
              ></v-select>
            </v-flex>

            <!-- <v-flex xs12 sm12 md12>
              <v-textarea
                v-model="list.desc"
                label="Description"
                placeholder="(optional)"
              ></v-textarea>
            </v-flex> -->
          </v-row>
        </v-card-text>

        <div
          v-if="formTitle != 'New'"
          class="row"
          style="margin: 0px; padding: 0px 15px"
        >
          <v-flex
            xs8
            sm8
            md8
          >
            <p style="margin:3px 0px 0px 0px; font-size: 13px">
              *want to delete this list?
            </p>
          </v-flex>
          <v-flex
            xs4
            sm4
            md4
            style="text-align: right"
          >
            <v-btn
              color="error"
              small
              style="text-transform: uppercase"
              @click="removeList(list)"
            >Delete</v-btn>
          </v-flex>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="cancelList"
          >Cancel</v-btn>
          <v-btn
            v-if="formTitle == 'New'"
            color="primary"
            @click="addList"
          >Create</v-btn>
          <v-btn
            v-else
            color="primarygrad white--text"
            @click="updateList"
          >Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- assign member dialog -->
    <v-dialog
      v-model="assignMemberDialog"
      width="400"
      persistent
    >
      <v-card v-if="Object.keys(itemSelected).length > 0">
        <v-card-title class="primary white--text">Assign Users</v-card-title>
        <v-card-text style="padding: 40px 20px 0px 20px;">
          <v-flex
            xs12
            sm12
            md12
          >
            <v-select
              v-model="selectUsersForAssignment"
              label="Select Users"
              :items="calcUsers"
              item-text="name"
              item-value="id"
              outlined
              chips
              multiple
              dense
            ></v-select>
          </v-flex>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="assignMemberDialog = false"
          >Close</v-btn>
          <v-btn
            color="primary"
            @click="assignMember"
          >Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- team dialog -->
    <v-dialog
      v-model="addTeamDialog"
      persistent
      width="400"
    >
      <v-card>
        <v-card-title class="primary white--text">{{ formTitle }}</v-card-title>
        <v-card-text style="padding: 20px">
          <v-row style="margin: 0px">
            <v-flex
              xs12
              sm12
              md12
            >
              <v-text-field
                v-model="teamItem.name"
                label="Name*"
              ></v-text-field>
            </v-flex>

            <v-flex
              xs12
              sm12
              md12
            >
              <v-textarea
                v-model="teamItem.desc"
                label="Description"
              ></v-textarea>
            </v-flex>
          </v-row>
        </v-card-text>
        <div
          v-if="formTitle != 'New'"
          class="row"
          style="margin: 0px; padding: 0px 15px"
        >
          <v-flex
            xs8
            sm8
            md8
          >
            <p style="margin:3px 0px 0px 0px; font-size: 13px">
              *want to delete this list?
            </p>
          </v-flex>
          <v-flex
            xs4
            sm4
            md4
            style="text-align: right"
          >
            <v-btn
              color="error"
              small
              style="text-transform: uppercase"
              @click="delItem(teamItem)"
            >Delete</v-btn>
          </v-flex>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="addTeamDialog = false"
          >close</v-btn>
          <v-btn
            v-if="formTitle == 'New'"
            color="primary"
            @click="saveTeam"
          >Create</v-btn>
          <v-btn
            v-if="formTitle == 'Edit'"
            color="primary"
            @click="saveTeam"
          >Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Loader -->
    <v-dialog
      v-model="loader"
      persistent
      width="300"
    >
      <v-card
        color="primary white--text"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Preloader -->
    <v-card-text
      v-if="preloader"
      style="padding: 50px"
    >
      <div
        style="text-align:center;"
        align="center"
      >
        <v-progress-circular
          indeterminate
          color="green"
          :size="38"
        ></v-progress-circular>
      </div>
    </v-card-text>
    <!-- change team dialog -->
    <v-dialog
      v-model="changeTeamDialog"
      persistent
      width="400"
    >
      <v-card>
        <v-card-title class="primary white--text">Change Team</v-card-title>
        <v-card-text style="padding: 40px 20px 0px 20px;">
          <v-flex
            xs12
            sm12
            md12
          >
            <!-- for team manager -->
            <v-autocomplete
              v-model="teamsIds"
              :multiple="accountSelected.role == 'Teammanager'"
              :items="dataSets"
              item-text="name"
              item-value="id"
              label="Select Teams*"
              chips
            ></v-autocomplete>
          </v-flex>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="changeTeamDialog = false"
          >Close</v-btn>
          <v-btn
            color="primary"
            @click="changeTeam"
          >Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import fb from "../js/firebase";
import ChipWithRole from "../components/chipwithrole.vue";
const _ = require("lodash");
export default {
  components: {
    ChipWithRole
  },
  data: () => {
    return {
      teamsIds: [],
      teamsId: "",
      accountSelected: {},
      users: [],
      dataSets: [],
      preloader: false,
      loader: false,
      listDialog: false,
      itemSelected: {},
      list: {
        name: "",
        teamsId: "",
        desc: ""
      },
      editedIndex: -1,
      selectUsersForAssignment: [],
      assignMemberDialog: false,
      selectedList: "",
      assignedUsers: [],
      addTeamDialog: false,
      companyManager: {},

      teamItem: {
        name: "",
        desc: ""
      },
      changeTeamDialog: false,
      teamForMoving: ""
    };
  },
  computed: {
    ...mapGetters(["ENDPOINT", "user", "dispoList", "teams", "selectedTeam"]),
    formTitle() {
      return this.editedIndex === -1 ? "New" : "Edit";
    },
    teams: {
      get(v) {
        return this.$store.getters.teams;
      },
      set(v) {
        this.$store.dispatch("getTeams", this.$store.getters.user);
      }
    },
    pageTitle() {
      return (
        this.$router.currentRoute.query.topic.charAt(0).toUpperCase() +
        this.$router.currentRoute.query.topic.slice(1)
      );
    },
    calcUsers(self) {
      return _.filter(self.users, u => {
        var teams = u.teamsId;
        for (var t in teams) {
          if (teams[t] == self.itemSelected.teamsId) {
            return u;
          }
        }
      });
    }
  },
  watch: {
    selectedTeam() {
      this.refresh();
    }
  },
  mounted() {},
  created() {
    this.refresh();
  },
  methods: {
    // Remove List-Account & Team-Account Relation
    deleteAssignmentAndUser(user, teamOrList) {
      const self = this;
      let deleteType = self.$router.currentRoute.query.topic;
      let swalText = "";
      let api = "";
      let payload = { accountIds: [user.id] };

      if (deleteType == "assignments") {
        swalText = `${user.name} will be removed from assignment?`;
        api = `${self.ENDPOINT}/listsAccountThroughs/remove-list-account-through`;
        payload.listsId = teamOrList.id;
      }

      if (deleteType == "teams") {
        swalText = `Are you sure you want to delete ${user.name}?`;
        api = `${self.ENDPOINT}/AccountTeamsThroughs/remove-team-account-through`;
        payload.teamId = teamOrList.id;
      }

      self
        .$swal({
          type: "warning",
          title: `Are you sure?`,
          text: swalText,
          showCancelButton: true,
          confirmButtonColor: "#1bca3f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes"
        })
        .then(result => {
          if (result.value) {
            self.loader = true;
            self.$http
              .post(api, payload)
              .then(response => {
                self.loader = false;
                self.$swal({ type: "success", text: "Successful" });
                self.refresh();
              })
              .catch(err => {
                self.loader = false;
                self.$swal({ type: "error", text: err.body.error.message });
              });
          }
        });
    },
    /**Other API calls */
    // refresh data
    async refresh() {
      try {
        this.preloader = true;
        this.dataSets = [];
        //init
        if (this.$router.currentRoute.query.topic == "assignments") {
          await this.fetchLists(); // fetch list
        } else {
          await this.fetchTeams(); // fetch teams
        }
        this.fetchUsers();
        // this.fetchCount();
        this.preloader = false;
      } catch (e) {
        this.preloader = false;
      }
    },
    /** Lists Sections */
    cancelList() {
      this.listDialog = false;
    },
    listEdit(list) {
      this.editedIndex = this.dataSets.indexOf(list);
      this.list = Object.assign(list);
      this.getUserByListAndTeams(list);
      this.listDialog = true;
    },
    // Open list dialog
    openAddList() {
      this.editedIndex = -1;
      this.list = {
        name: "",
        teamsId: "",
        desc: ""
      };
      this.listDialog = true;
    },
    /** recieve teams array of ids */
    getTeamIds() {
      var teams = [];
      if (this.selectedTeam == "allTeams") {
        teams = _.map(this.teams, "id");
      } else {
        teams.push(this.selectedTeam);
      }
      teams = _.without(teams, "allTeams");
      return teams;
    },
    // fetch all lists
    async fetchLists() {
      try {
        const self = this;
        self.preloader = true;
        self.dataSets = [];
        var teamIds = this.getTeamIds();
        var res = await self.$http.post(
          `${self.$store.state.ENDPOINT}/lists/fetch`,
          {
            teams: teamIds
          }
        );
        self.dataSets = res.body;

        var accounts = await self.$http.get(
          `${self.$store.state.ENDPOINT}/accounts?filter[where][companyId]=${self.$store.getters.user.companyId}`
        );

        var manager = _.filter(accounts.body, d => {
          return d.role.toLowerCase() == "manager";
        });

        this.companyManager = manager[0];

        self.preloader = false;
      } catch (e) {
        self.preloader = false;
      }
    },
    // update list
    async updateList() {
      const self = this;
      self.loader = true;
      try {
        var listInfo = await self.$http.get(
          self.$store.state.ENDPOINT + "/lists/" + this.list.id
        );
        if (self.list.teamsId != listInfo.body.teamsId) {
          if (self.assignedUsers.length > 0) {
            self.loader = false;
            return self.$swal({
              type: "warning",
              text: "Remove the assignments before moving it to other team"
            });
          }
        }
        await self.$http.patch(
          `${self.$store.state.ENDPOINT}/lists/${self.list.id}`,
          {
            name: self.list.name,
            desc: self.list.desc,
            teamsId: self.list.teamsId
          }
        );
        self.listDialog = false;
        self.loader = false;
        self.preloader = true;
        self.refresh();
      } catch (e) {
        self.loader = false;
        self.$swal({
          type: "warning",
          text: "Error occured. Please try again."
        });
      }
    },
    // add list
    async addList() {
      const self = this;
      if (this.list.name == "") {
        return this.$swal({
          type: "warning",
          text: "You need to write something!"
        });
      }

      if (self.$store.getters.selectedTeam == "allTeams") {
        return self.$swal({
          type: "warning",
          text: "Select a team from the side-panel before creating list."
        });
      }

      self.loader = true;
      var jsonObj = {
        name: this.list.name,
        teamsId: this.selectedTeam,
        desc: this.list.desc,
        companyId: this.user.companyId
      };
      this.$http
        .post(this.$store.state.ENDPOINT + "/lists", jsonObj)
        .then(function() {
          self.listDialog = false;
          self.loader = false;
          self.preloader = true;
          fb.log("list_add");
          self.$swal({
            type: "success",
            text: `${this.list.name} is added successfully`
          });
          self.list = {
            name: "",
            desc: "",
            teamsId: ""
          };
          self.refresh();
        })
        .catch(err => {
          self.loader = false;
          this.$swal({
            type: "error",
            text: "Sorry, same list name is present"
          });
        });
    },
    // remove list with all the contacts assigned to it
    removeList(list) {
      const self = this;
      self
        .$swal({
          type: "warning",
          title: `Are you sure?`,
          text: `This will delete all records of this list`,
          showCancelButton: true,
          confirmButtonColor: "#1bca3f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes"
        })
        .then(result => {
          if (result.value) {
            self.loader = true;
            self.$http
              .delete(this.$store.state.ENDPOINT + "/lists/" + list.id)
              .then(function() {
                self.refresh();
                self.listDialog = false;
                self.loader = false;
                self.preloader = true;
                self.$swal({ type: "success", text: "Successful" });
                fb.log("list_delete");
              })
              .catch(err => {
                self.loader = false;
                self.$swal({ type: "error", text: "Something went wrong" });
              });
          }
        });
    },
    /** Users Sections */
    fetchUsers() {
      this.users = [];
      var filter = {
        where: {
          companyId: {
            eq: this.$store.getters.user.companyId
          },
          role: {
            neq: "ApiManager"
          }
        },
        include: [
          {
            relation: "lists",
            scope: {
              fields: ["id", "name"]
            }
          }
        ]
      };
      this.$http
        .get(
          `${this.$store.state.ENDPOINT}/accounts?filter=${encodeURIComponent(
            JSON.stringify(filter)
          )}`
        )
        .then(response => {
          this.users = response.body;
        })
        .catch(err => {
          //error
        });
    },
    //get users by list id
    getUserByListAndTeams(list) {
      this.assignedUsers = [];
      this.selectUsersForAssignment = [];
      this.$http
        .post(
          `${this.ENDPOINT}/listsAccountThroughs/get-account-through-list`,
          {
            listId: list.id
          }
        )
        .then(response => {
          this.assignedUsers = response.body;
          this.selectUsersForAssignment = _.map(response.body, "id");
        })
        .catch(e => {
          this.preloader = false;
          this.$swal({ type: "error", text: "Something went wrong" });
        });
    },
    // open assingnment dialog
    async selectedAssignMember(list) {
      this.itemSelected = Object.assign({}, list);
      await this.getUserByListAndTeams(list);
      this.assignMemberDialog = true;
    },
    // assign member
    async assignMember() {
      const self = this;

      await self.$http
        .post(
          `${self.ENDPOINT}/listsAccountThroughs/set-list-account-through`,
          {
            listsId: self.itemSelected.id,
            accountIds: self.selectUsersForAssignment
          }
        )
        .then(response => {
          self.selectUsersForAssignment = [];
          fb.log("list_assign");
          self.loader = false;
          self.$swal({
            type: "success",
            text:
              "Successfully assigned list, Now the member can dial this list from the app"
          });
          self.assignMemberDialog = false;
          self.fetchLists();
        })
        .catch(err => {
          self.close();
          self.refresh();
          console.log("Error", err);
        });
    },

    /**Teams Section */
    //teams section
    async fetchTeams() {
      try {
        const self = this;
        self.preloader = true;
        self.dataSets = [];
        var uri = 0;
        if (this.$store.getters.user.role == "Manager") {
          uri = `${self.$store.state.ENDPOINT}/teams?filter[include]=accounts&filter[where][companyId]=${self.$store.getters.user.companyId}`;
        } else if (this.$store.getters.user.role == "Teammanager") {
          var filter = {
            include: [
              {
                relation: "teams",
                scope: {
                  include: [{ relation: "accounts" }]
                }
              }
            ]
          };
          uri = `${this.$store.state.ENDPOINT}/accounts/${
            this.$store.getters.user.id
          }?filter=${encodeURIComponent(JSON.stringify(filter))}`;
        } else {
          uri = `${self.$store.state.ENDPOINT}/teams/${this.$store.getters.user.teamsId}?filter[include]=accounts&filter[where][companyId]=${self.$store.getters.user.companyId}`;
        }
        var res = await self.$http.get(uri);
        res = res.body;
        if (this.$store.getters.user.role == "Teammanager") {
          res = res.teams;
        }
        self.dataSets = res;

        console.log("self.dataSets", self.dataSets);

        var accounts = await self.$http.get(
          `${self.$store.state.ENDPOINT}/accounts?filter[where][companyId]=${self.$store.getters.user.companyId}`
        );

        var manager = _.filter(accounts.body, d => {
          return d.role.toLowerCase() == "manager";
        });

        this.companyManager = manager[0];

        self.preloader = false;
      } catch (e) {
        console.log(e);
        self.preloader = false;
      }
    },
    saveTeam() {
      if (this.teamItem.name == "") {
        return this.$swal({ type: "warning", text: "Blank Team Name" });
      }
      if (this.editedIndex > -1) {
        this.loader = true;
        this.$http
          .patch(`${this.$store.state.ENDPOINT}/teams/${this.teamItem.id}`, {
            name: this.teamItem.name,
            desc: this.teamItem.desc,
            companyId: this.user.companyId,
            modifiedOn: new Date()
          })
          .then(response => {
            fb.log("team_update");
            this.$store.dispatch("getTeams", {
              role: this.user.role,
              teamsId: this.user.teamsId,
              companyId: this.user.companyId
            });
            this.loader = false;
            this.preloader = true;
            this.$swal({
              type: "success",
              text: `${this.teamItem.name} updated successfully`
            });
            this.teamItem = {
              name: "",
              desc: ""
            };
            this.refresh();
            this.addTeamDialog = false;
          })
          .catch(e => {
            this.loader = false;
            this.addTeamDialog = true;
            this.$swal({
              type: "error",
              text: "Something went wrong while editing team"
            });
          });
      } else {
        this.teamItem.companyId = this.user.companyId;
        this.loader = true;
        this.$http
          .post(`${this.$store.state.ENDPOINT}/teams/add`, {
            data: this.teamItem
          })
          .then(response => {
            fb.log("team_add");
            this.$store.dispatch("getTeams", {
              role: this.user.role,
              teamsId: this.user.teamsId,
              companyId: this.user.companyId
            });
            this.loader = false;
            this.preloader = true;
            this.$swal({
              type: "success",
              text: `${this.teamItem.name} created successfully`
            });
            this.teamItem = {
              name: "",
              desc: ""
            };
            this.addTeamDialog = false;
            this.refresh();
          })
          .catch(e => {
            this.loader = false;
            this.addTeamDialog = true;
            this.$swal({
              type: "error",
              text: "Something went wrong while creating team"
            });
          });
      }
    },
    editItem(item) {
      this.editedIndex = this.dataSets.indexOf(item);
      this.teamItem = Object.assign(item);
      this.addTeamDialog = true;
    },
    openDialog() {
      this.editedIndex = -1;
      this.teamItem = {
        name: "",
        desc: ""
      };
      this.addTeamDialog = true;
    },
    delItem(item) {
      this.$swal
        .fire({
          text: `Whole team (${item.name}) will be deleted. Continue ?`,
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#1bca3f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes"
        })
        .then(result => {
          if (result.value) {
            this.loader = true;
            this.$http
              .delete(`${this.$store.state.ENDPOINT}/teams/${item.id}`)
              .then(
                response => {
                  this.loader = false;
                  this.preloader = true;
                  this.$swal({
                    type: "success",
                    text: `${item.name} deleted successfully`
                  });
                  this.refresh();
                  this.addTeamDialog = false;
                  this.$store.dispatch("getTeams", {
                    role: this.user.role,
                    teamsId: this.user.teamsId,
                    companyId: this.user.companyId
                  });
                },
                error => {
                  this.loader = false;
                  this.$swal({
                    type: "error",
                    text: error.body.error.message
                  });
                }
              );
          }
        });
    },

    openChangeTeam(user) {
      this.accountSelected = Object.assign({}, user);
      this.teamsIds = this.accountSelected.teamsId;
      this.changeTeamDialog = true;
    },
    changeTeam() {
      const self = this;
      var teams = this.teamsIds;
      if (Array.isArray(teams)) {
        // teams = teams;
      } else {
        teams = [teams];
      }
      var jsonObj = {
        teamsId: teams,
        modifiedOn: new Date()
      };
      self.loader = true;
      self.$http
        .patch(
          `${self.$store.state.ENDPOINT}/accounts/${this.accountSelected.id}`,
          jsonObj
        )
        .then(response => {
          self.loader = false;
          self.preloader = true;
          this.$swal({ type: "success", text: "Successful" });
          this.refresh();
          this.changeTeamDialog = false;
        })
        .catch(err => {
          self.loader = false;
          this.changeTeamDialog = false;
          this.$swal({ type: "error", text: "Error while switching teams" });
        });
    }
  }
};
</script>

<style scoped>
.card_title {
  font-weight: 500 !important;
  font-size: 14px;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  /* opacity: 0.7; */
}
.add_button {
  padding: 10px !important;
  cursor: pointer;
  font-weight: 500;
  letter-spacing: 0.5px;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 7px;
}
.add_button:hover {
  padding: 10px !important;
  cursor: pointer;
  transition-delay: 0.1s;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.5px;
  font-size: 14px;
  box-shadow: 1px 3px 9px #c3c3c3 !important;
}
.v-divider {
  margin: 10px 0px;
  opacity: 0.5;
}

.chipCustom {
  color: black;
  background-color: rgb(224, 224, 224);
  border-radius: 10px;
  padding: 2%;
  position: relative;
  z-index: 100;
}
.chipBorder {
  color: white;
  padding: 1%;
  font-size: xx-small;
  margin-left: 12px;
  margin-right: 12px;
  margin-top: -7px;
  border-radius: 5px;
  /* border: 2px solid #1bca3f; */
  background-color: #1bca3f;
  text-align: center;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: 700;
  /* padding-top: 0px; */
  /* vertical-align: bottom; */
  /* display: flex; */
  /* align-items: center; */
  /* display: table-cell; */
}
</style>
