<template>
  <div class="chipWithRole">
    <div class="chipCustom">
      {{ text }}
      <span
        v-if="
          extraText.toLowerCase() != 'manager' &&
            extraText.toLowerCase() != 'apimanager'
        "
        @click="$emit('crossClick')"
      >
        <v-icon class="material" style="font-size: 18px;"
          >mdi-close-circle</v-icon
        >
      </span>
    </div>
    <div class="chipExtra">
      {{ extraText }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import fb from "../js/firebase";
export default {
  props: ["text", "extraText"],
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["ENDPOINT", "selectedTeam"]),
  },

  mounted() {},
  created() {},
  methods: {},
};
</script>

<style scoped>
.chipWithRole {
  margin: 2px;
  display: inline-grid;
}
.chipCustom {
  background-color: #d7d4d4;
  color: black;
  border-radius: 10px;
  padding: 6px;
  z-index: 2 !important;
}
.chipExtra {
  color: white;
  padding: 1%;
  font-size: 4pt;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: -8px;
  border-radius: 5px;
  /* border: 2px solid #1bca3f; */
  background-color: #1bca3f;
  text-align: center;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: 700;
  display: table-cell;
  vertical-align: bottom;
  /* padding-top: 0px; */
  /* vertical-align: bottom; */
  /* display: flex; */
  /* align-items: center; */
  /* display: table-cell; */
}
/* .chipExtra span {
  align-self: flex-end;
} */
</style>
